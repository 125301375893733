// //
// // overriding
// // --------------------------------

// @import 'react-redux-toastr/src/styles/index';

$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

$primary: #e83734;
$secondary: #a32a29;
$gray-100: #F7F7F7;
$gray-400: #868686;
$gray-500: #757575;
$gray-600: #4F4F4F;
$gray-700: #3F4241;
$gray-800: #0F1312;
$gray-900: #0F1312;
$border-color: #E5E5EA;
$text-muted: $gray-400;
$text-grey: #686868;
$dark: #000;
$white: #fff;
$text-color: #0F1312;
$grey-bg: #fafafb;
$grey-bg-secondary: #f5f6f8;
$footer-text: #3f4141;
$link-color: #a32a29;
$link-hover-color: #a32a29;
$font-weight-medium: 500;
$body-bg: #f7f7f7;
$body-color: #0F1312;
$font-weight-base: $font-weight-medium;

$font-family-sans-serif: 'NoirPro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-arabic: 'NoirPro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base: 'NoirPro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-size-base: 0.9375rem;
$font-size-lg: 1.0625rem;
$font-size-sm: 0.9375rem;

$input-border-color: #DBDBDB;
